import { Component, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { Attendee } from '@app/core/models/attendees.model';
import { UserService } from '@app/core/services/user.service';
import { SwiperDirective, SwiperComponent } from 'ngx-swiper-wrapper';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { SelectorDialogComponent } from '@app/modules/selector-dialog/selector-dialog.component';

interface AttendeeExtras{
  accountno: string,
  title: string,
  firstTime: string,
  potential: string,
  ext1: string,
  ext2: string,
  end1: string,
  end2: string,
  end3: string,
  end4: string,
  end5: string,
  residence: string,
  touched: boolean
}
interface Touched{
  touched: boolean
}

@Component({
  selector: 'app-gm-update',
  templateUrl: './gm-contact-update.component.html',
  styleUrls: ['./gm-update.component.scss']
})
/**
 * A modal that's responsible for handling updating the following in goldmine
 * Master Contact Record, Calander, Attendee Contact Record
 */
export class GMContactUpdateComponent {

  @ViewChild('attendeesCarousel') attendeesCarousel: SwiperComponent;
  //TODO, study up on form builder
  public form: FormGroup;

  public attendees: Attendee[];
  public touchedAttendees: Touched[] = []; //data relevant to MM Database
  public attendeeData: AttendeeExtras[] = []; //data relevant to GoldMine

  public curIndex: number;

  public indvPotentialOptions: any[] = [];
  public firstTimeMeetOptions: any[] = [];

  public tooltips: {} = {};
  
  constructor(
    private userService: UserService,
    private backendService: BackendConnectionService,
    private utilities: UtilitiesService,
    public dialogRef: MatDialogRef<GMContactUpdateComponent>,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) 
  {
    this.form = new FormGroup({
      name: new FormControl()
    });

    //Get drowpdown optoins from the gm_options table
    this.backendService.getRequest(this.userService.token,
      "/fetchDb?select=*&table=gm_options&column=area&value=att").subscribe(
        (res: any[])=>{
          res.forEach(element => {
            if(element.key == 'UIPTENTIALV'){
              let split = element.value.split("//");
              let index =  +split[1].substring(0, 2);
              if(index){
                this.indvPotentialOptions[index - 1] = split[0];
              }
            }
            if(element.key == 'UFRSTENCNTV'){
              this.firstTimeMeetOptions.push(element.value);
            }
          });
          this.firstTimeMeetOptions.sort();
        },
        (err)=>{
          console.log(err);
        }
      );

    this.attendees = this.dialogData.attendees;

    //Push a brand new list of all the attendees and send to the backend
    //to pull extra GoldMine data about each attendee
    this.attendees.forEach( (person) => {
      this.touchedAttendees.push({
        touched: false
      });
      this.attendeeData.push(
        {
          accountno: person.gm_account_num,
          title: "",
          firstTime: "",
          potential: "",
          ext1: "",
          ext2: "",
          end1: "",
          end2: "",
          end3: "",
          end4: "",
          end5: "",
          residence: "",
          touched: false
        }
      );
    });

    //Get extra data for each contact from GoldMine
    this.backendService.postRequest(this.userService.token, '/getContactExtras',
      this.attendeeData
    ).subscribe (
      (res) => {
        if(res){
          this.attendeeData = res;
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.cd.detectChanges();
      }
    );
    this.curIndex = this.dialogData.index;

    //Store the tooltips for this area. They are based on ttid
    this.utilities.toolTips.forEach(tip =>{
      if(tip.ttid == 1){
        this.tooltips['indvPotential'] = tip.value;
      }
    });
  }

  updateGoldMine(){
    //Build the data to be sent
    let data = [];
    this.attendees.forEach((person, index)=>{
      if(!this.touchedAttendees[index].touched && !this.attendeeData[index].touched) return;

      let tempData = {
        aid: person.aid 
      };
      //Data going to CURL and database update
      if(this.touchedAttendees[index].touched){
        tempData['curl'] = {
          phone1: person.phone1,
          phone2: person.phone2,
          email: person.email
        };
      }
      //Data going to query
      if(this.attendeeData[index].touched){
        tempData['gmQuery'] = {
          //accountno: person.gm_account_num,
          contact1: {
            ACCOUNTNO: person.gm_account_num,
            TITLE : this.attendeeData[index].title,
            EXT1: this.attendeeData[index].ext1,
            EXT2: this.attendeeData[index].ext2
          },
          contact2: {
            ACCOUNTNO: person.gm_account_num,
            UIPTENTIAL: this.attendeeData[index].potential,
            UFRSTENCNT: this.attendeeData[index].firstTime,
            UPRGMPOT01: this.attendeeData[index].end1,
            UPRGMPOT02: this.attendeeData[index].end2,
            UPRGMPOT03: this.attendeeData[index].end3,
            UPRGMPOT04: this.attendeeData[index].end4,
            UPRGMPOT05: this.attendeeData[index].end5,
            URESIDENCE: this.attendeeData[index].residence
          }
        };
      }
      data.push(tempData);
    });
    //Send data to backend to update the database OR goldmine OR both
    this.backendService.postRequest(this.userService.token, '/updateContacts',
    {
      data
    }).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
        alert("Warning, changes may not have been saved!\nPlease check your connection!\n" 
          + "Error:" +  err);
      }
    );
    this.dialogRef.close();
  }

  openInfoDialog(potential: string){
    let toolTip = this.utilities.toolTips.find(tip => tip.helper == potential);
    let dialogRef = this.dialog.open(SelectorDialogComponent, {
      data: {
        selectorType: 'confirmOnly',
        message: toolTip.value
      }
    });
  }

  markAttendeeChanged(index: number){
    this.touchedAttendees[index].touched = true;
  }

  markAttendeeDataChanged(index: number){
    this.attendeeData[index].touched = true;
  }

  onIndexChange(index: number){
    this.curIndex = index;
  }

  updatePhone(index: number, select: number){
    let telephone = this.attendees[index][`phone${select}`];
    telephone = telephone.replace(/[^0-9\.]+/g, '');
    if(telephone.length == 10){
      telephone = telephone.slice(0, 3) 
      + "-" + telephone.slice(3,6) 
      + "-" + telephone.slice(6);
    }
    this.attendees[index][`phone${select}`] = telephone;
    this.touchedAttendees[index].touched = true;
  }

  onNoClick(){
    this.dialogRef.close();
  }
}
