import { Component, OnInit } from '@angular/core';
import { GoldmineService } from '@app/core/services/goldmine.service';
import { UtilitiesService } from '@app/core/services/utilities.service';

declare let jsPDF;
import 'jspdf-autotable';
import { Router } from '@angular/router';

@Component({
    selector: 'app-tripoverview',
    templateUrl: './tripoverview.component.html',
    styleUrls: ['./tripoverview.component.scss']
})
export class TripOverviewComponent implements OnInit {

    public isCollapsed = true;

    constructor(
        public goldMine: GoldmineService,
        private utilities: UtilitiesService,
        private router: Router
    ) { }

    ngOnInit() {
        console.log("trip overview");
    }

    viewWeekPlanner(eventIndex: number){
        this.router.navigate(['/splash/planner/week-overview']);
        this.goldMine.eventExplodeIndex = eventIndex;
        localStorage.setItem('eventExplodeIndex', String(eventIndex));
    }

    generateTripOverview(eventIndex: number) {
        let event = this.goldMine.events[eventIndex];
        let doc = new jsPDF('landscape');
        doc.text(
            this.utilities.dateToStringMonthDay(event.convertOnDate) + " - "
            + this.utilities.dateToStringMonthDay(event.convertEndDate) + " , " + event.location, 14, 15);

        let entries = {
            'Sunday' : [],
            'Monday' : [],
            'Tuesday' : [],
            'Wednesday' : [],
            'Thursday' : [],
            'Friday' : [],
            'Saturday' : []
        };
        event.weekDays.forEach( day => {
            let dayIndex = day.convertDate.toLocaleString('en-us', { weekday: 'long' }).replace(/[^ -~]/g,'');
            day.meetings.forEach( meeting => {
                let primaryName = 'NONE';
                let primaryTitle = '';
                if(meeting.primaryContact[0]){
                    primaryName = meeting.primaryContact[0].full_name || 'NONE';
                    primaryTitle = meeting.primaryContact[0].title || '';
                }
                entries[dayIndex].push([
                    meeting.ref, //Reference
                    this.utilities.dateToStringDay(day.convertDate), //Day
                    '', //Status
                    meeting.company, //Company
                    primaryName,
                    primaryTitle,
                    meeting.city, //City
                    this.utilities.formatTime(meeting.ontime) //Time
                ]);
            });
        });

        let finalEntries = [];
        Object.keys(entries).forEach( key => {
            let entry = entries[key];
            finalEntries.push([{ content: key + " " + entry.length, 
                colSpan: 8,
                styles: { fillColor: [113, 178, 235], fontStyle: 'bold' }
            }]);
            entry.forEach(line => {
                finalEntries.push(line);
            });
        })


        doc.autoTable({
            startY: 20,
            head: [
                ['Reference', 'Day', 'Status', 'Company', 'Contact', 'Title', 'City', 'Time']
            ],
            body: finalEntries,
            styles: {
                lineWidth: 0.2
            }
        });
        doc.autoPrint();
        doc.output('dataurlnewwindow');
    }

    toggleCollapse(index: number) {
        this.goldMine.events[index].isCollapsed = !this.goldMine.events[index].isCollapsed;
    }

    dateToStringMonthDay(date: Date): string {
        return this.utilities.dateToStringMonthDay(date);
    }

    dateToStringDay(date: Date): string {
        return this.utilities.dateToStringDay(date);
    }

    /**
     * PIPES INTO A NEW MEETING DETAILS COMPONENT, SHOWING THE DAY
     * ALSO UPDATES SPLASH AS TO WHICH MEETING IS CURRENTLY HIGHLITED
     * @param eventIndex 
     * @param weekdayIndex 
     */
    setExplodeIndex(eventIndex: number, weekdayIndex: number) {
        this.goldMine.eventExplodeIndex = eventIndex;
        localStorage.setItem('eventExplodeIndex', String(eventIndex));
        this.goldMine.weekdayExplodeIndex = weekdayIndex;
        localStorage.setItem('weekdayExplodeIndex', String(weekdayIndex));
        /* 
        * Reset the meeting index because a new weekday was selected
        * inform splash component that we are piping into a new meeting index
        */
        this.goldMine.updateMeeting(); //also sets goldMine's index to 0
        localStorage.setItem('meetingIndex', '0');

    }
}
