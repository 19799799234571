import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'app-final-selector-dialog',
  templateUrl: './final-selector-dialog.component.html',
  styleUrls: ['./selector-dialog.component.scss']
})
export class FinalSelectorDialogComponent {

  public message: SafeHtml;
  public codeOptions: any[] = []
  public resultSelection: string;
  public codeSelection: string;
  public isTradeShow: boolean;

  constructor(
    private sanitizer: DomSanitizer,
    private backendService: BackendConnectionService,
    private userService: UserService,
    public dialogRef: MatDialogRef<FinalSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.message = this.sanitizer.bypassSecurityTrustHtml(this.dialogData.message);
    this.isTradeShow = this.dialogData.isTradeShow;

    //Get drowpdown optoins from the gm_options table
    this.backendService.getRequest(this.userService.token,
      "/fetchDb?select=*&table=gm_options&column=area&value=fin").subscribe(
        (res: any[]) => {
          res.forEach(option => {
            let split = option.value.split("//");
            this.codeOptions.push({ value: split[0].trim(), name: split[1].trim() });
          })
          this.codeOptions.sort((a, b) => (a.value > b.value)? 1 : -1);
          //Set defaults for dropdowns
            this.resultSelection = "SUC";
            this.codeSelection = this.codeOptions[0].value;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  onNoClick() {
    this.dialogRef.close({
      res: false,
      selections: {
        result: this.resultSelection,
        code: this.codeSelection
      }
    });
  }

  public closeDialog(userResponse: boolean) {
    this.dialogRef.close({
      res: userResponse,
      selections: {
        result: this.resultSelection,
        code: this.codeSelection
      }
    });
  }

}
