import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { MatDialog } from '@angular/material';
import { AppointmentsignupDialogComponent } from './appointmentsignup-dialog/appointmentsignup-dialog.component';
import { SelectorDialogComponent } from './selector-dialog/selector-dialog.component';

@Component({
    selector: 'app-appointmentsignup',
    templateUrl: './appointmentsignup.component.html',
    styleUrls: ['./appointmentsignup.component.scss'],
    host: {
        '(window:resize)': 'onResize($event)'
    },
    encapsulation: ViewEncapsulation.None
})
export class AppointmentsignupComponent implements OnInit {

    public name: string;
    public innerHeight: number;
    public data: any;
    public timeZone: string;
    private custEmail: string;
    public weekStarted: boolean = false;
    public user: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private backendService: BackendConnectionService,
        private utilities: UtilitiesService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.innerHeight = window.innerHeight;
        this.timeZone = "(" + new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1] + ")";
        //Query parameters
        this.route.queryParams.subscribe((params: Params) => {
            if(params.email){
                this.custEmail = params.email;
            }
        });
        function getOrdinalNum(n) {
            return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
        }
        //Route parameters
        this.route.params.subscribe((params: Params) => {
            if (params.name) {
                this.backendService.getRequest('null', `/signupLoad?name=${params.name}`).subscribe(
                    (response: any) => {
                        if(!response.status){
                            this.showLogin;
                        }
                        this.user = response.data.user;
                        this.data = [];
                        if(response.data.meetings.length){
                            // let curMonth = null;
                            // let curWeek = null;
                            let curDay = undefined;
                            let colorBackground = true;
                            response.data.meetings.forEach(meeting => {

                                let t = meeting.date.split(/[- :]/);
                                let onDate = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
                                console.log(onDate.toUTCString());
                                let week = this.utilities.getWeekOfMonth(onDate) + 1; //Number
                                let month = onDate.getMonth(); //Number
                                let year = onDate.getFullYear();

                                //Calc the end datetime from the duration.
                                let endDate = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
                                meeting.dur = +meeting.dur || 0;
                                if(meeting.dur > 0){
                                    endDate.setMinutes( endDate.getMinutes() + meeting.dur );
                                }

                                //Date Formal
                                meeting.dateFormat = this.utilities.dateToStringDay(onDate, true);
                                //Weekday formal
                                meeting.weekday = onDate.toLocaleDateString('en-us', { weekday: 'long'});
                                //Time formal
                                meeting.time = onDate.toLocaleTimeString('en-us', {hour: 'numeric', minute: 'numeric'})
                                    + " - " + endDate.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric'});

                                if(curDay != meeting.weekday){
                                    meeting.newWeek = true;
                                }
                                curDay = meeting.weekday;

                                // let twoDigit = onDate.toLocaleTimeString('en-us', {day: '2-digit'});
                                let twoDigit = getOrdinalNum(onDate.getDate());
                                if(!this.data[`${year}_${month}_${week}`]){
                                    this.data[`${year}_${month}_${week}`] = {
                                        start: onDate,
                                        startFormal: twoDigit,
                                        end: onDate,
                                        endFormal: twoDigit,
                                        monthFormal: onDate.toLocaleString('en-us', {month: 'long'}),
                                        collapse: true,
                                        meetings: [meeting]
                                    };
                                    if(colorBackground){
                                        this.data[`${year}_${month}_${week}`].class = 'background';
                                    }
                                    colorBackground = !colorBackground;
                                } else {
                                    let data = this.data[`${year}_${month}_${week}`];
                                    if(onDate < data.start){
                                        data.start = onDate;
                                        data.startFormal = twoDigit;
                                    }
                                    if(onDate > data.end){
                                        data.end = onDate;
                                        data.endFormal = twoDigit;
                                    }
                                    data.meetings.push(meeting);
                                }
                                

                                // if(week != curWeek || month != curMonth){
                                //     let monthAndYearString = onDate.toLocaleString('en-us', { year: 'numeric', month: 'long'});
                                //     meeting.startWeek = monthAndYearString + ", Week " + week.toString(); 
                                // }

                                // curWeek = week;
                                // curMonth = onDate.getMonth();
                            });
                            console.log(this.data);
                        }
                    },
                    () => { //error
                        this.showLogin();
                    }
                )
            } else {
                this.showLogin();
            }
        });
    }

    private showLogin(){
        this.router.navigate(['/login']);
    }

    keys(object: Object) : Array<string> {
        return Object.keys(object);
    }

    public signup(group: string, index: number){
        console.log(group);
        /**
         * bdRep: this.bdRep,
            email: this.email,
            id: this.id,
            info: info
         */
        let meeting = this.data[group].meetings[index];
        let loaderDialogRef = this.dialog.open(AppointmentsignupDialogComponent, { 
            data: {
                bdRep: this.user,
                id: meeting.id,
                custEmail: this.custEmail,
                meetingTitle: meeting.dateFormat + ", " + meeting.time
            }
        });
        loaderDialogRef.afterClosed().subscribe(
            (res) => {
                if(res){
                    res = {...res, ...{
                        date: meeting.date,
                        theirTime: meeting.time + " " + this.timeZone,
                        dur: meeting.dur
                    }};
                    this.backendService.postRequest('null', '/signup', res).subscribe(
                        (response) => {
                            if(!response.status)
                                alert(response.message);
                            else {
                                this.dialog.open(SelectorDialogComponent, {
                                    data: {
                                        message: `Thank you for signing up. ${this.user.full_name} will be in touch with you shortly.`
                                    }
                                })
                                meeting.filled = 'pending';
                            }
                        }, (err) => console.log(err)
                    )
                }
            }
        )
    }

    onResize(event){
        this.innerHeight = event.target.innerHeight; // window width
    }

}
