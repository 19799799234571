import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';
import { GoldmineService } from '@app/core/services/goldmine.service';
import { Meeting } from '@app/core/models/goldmine.model';
import { NgForm } from '@angular/forms';
import { UserService } from '@app/core/services/user.service';
import { UtilitiesService } from '@app/core/services/utilities.service';

interface WebsiteData {
  CONTSUPREF: string,
  ZIP: string,
  RECID: string
}

@Component({
  selector: 'app-gm-update',
  templateUrl: './gm-app-update.component.html',
  styleUrls: ['./gm-update.component.scss']
})
/**
 * Dialog that is responsible for upating GoldMine's database for an appointment
 */
export class GMAppUpdateComponent {

  public meeting: Meeting;
  public dateTime: string;

  public potentialOptions: any[] = [];

  public websiteData: WebsiteData[] = [];

  constructor(
    private goldMine: GoldmineService,
    private userService: UserService,
    private utilities: UtilitiesService,
    private backendService: BackendConnectionService,
    public dialogRef: MatDialogRef<GMAppUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any //Not being used for this dialog
  ) 
  {
    this.meeting = this.goldMine.meeting;
    //Get drowpdown optoins from the gm_options table
    this.backendService.getRequest(this.userService.token,
      "/fetchDb?select=*&table=gm_options&column=area&value=app").subscribe(
        (res: any[])=>{
          res.forEach(element => {
            if(element.key == 'UPOTENTIALV'){
              let split = element.value.split("//");
              let index =  +split[1].substring(0, 2);
              if(index){
                this.potentialOptions[index - 1] = split[0];
              }
            }
          });
        },
        (err)=>{
          console.log(err);
        }
      );
    //Get master record extras from  Goldmine
    this.backendService.postRequest(this.userService.token,
      `/getMasterExtras`, { accountno: this.meeting.accountno }).subscribe(
        (res: WebsiteData[])=>{
          if(res){
            this.websiteData = res;
            this.websiteData.forEach(element => this.utilities.trimObjectStrings(element));
          } else {
            console.log("no websites found!");
          }
        },
        (err)=>{
          console.log(err);
        }
      );
  }

  updateGoldMine(gmUpdateForm: NgForm){

    let newWebsite = "";
    if(gmUpdateForm.value.new_website){
      newWebsite = gmUpdateForm.value.new_website;
    }
    let dateString = gmUpdateForm.value.meeting_date;
    let date = new Date(dateString);
    let time = date.toLocaleTimeString(
      'en-us', { hour12: false, hour: 'numeric', minute: 'numeric'}).replace(/[^ -~]/g,'');
    this.backendService.postRequest(this.userService.token, '/updateAppointment',
    {
      accountno:    this.meeting.accountno,
      recid:        this.meeting.recid,
      masterRecid:  this.meeting.masterRecid,
      //https://stackoverflow.com/questions/3605214/javascript-add-leading-zeroes-to-date
      date:         date.getFullYear() + '-' 
                      + ('0' + (date.getMonth()+1)).slice(-2) + '-' 
                      + ('0' + date.getDate()).slice(-2),
      time:         time,
      potential:    this.meeting.UPOTENTIAL,
      websiteData:  this.websiteData,
      newWebsite:   newWebsite
    }).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      }, 
      () => {
        //Update new time on the front end
        //For date a refresh will be necessary to repull the data from GoldMine
        this.meeting.ontime = time;
      }
    );
    this.dialogRef.close();
  }

  onNoClick(){
    this.dialogRef.close();
  }
}
