import { Component, OnInit, OnDestroy } from '@angular/core';
import { GoldmineService } from '@app/core/services/goldmine.service';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { MeetEvent } from '@app/core/models/goldmine.model';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';
import { UserService } from '@app/core/services/user.service';
import { ThemePalette } from '@angular/material/core';
import { LoaderDialogComponent } from '../loader-dialog/loader-dialog.component';
import { MatDialog } from '@angular/material';
import { SelectorDialogComponent } from '../selector-dialog/selector-dialog.component';

@Component({
    selector: 'app-planner',
    templateUrl: './planner.component.html',
    styleUrls: ['./planner.component.scss']
})
export class PlannerComponent implements OnInit, OnDestroy {

    public event: MeetEvent;
    private subs: Subscription[] = [];
    public color: ThemePalette = 'primary';
    public userName: string = '';

    //Signup meeting variables
    public signupStart: any;
    public signupCount: number;

    constructor(
        public goldMine: GoldmineService,
        public utilities: UtilitiesService,
        public userService: UserService,
        private backendConnection: BackendConnectionService,
        private dialog: MatDialog,
        private router: Router
    ) {
        this.signupCount = 1;
    }

    ngOnInit() {
        if(this.goldMine.meetingsLoaded){
            this._init();
        } else {
            this.subs.push(this.goldMine.meetingsSubject.subscribe(
                () => { this._init(); }
            ));
        }
    }

    private _init(){
        this.userName = this.userService.user.username;
        this.event = this.goldMine.event;
        this.signupStart = new Date(this.goldMine.event.ondate.split(" ")[0] + " 8:00:00");
    }

    public deleteSignupMeeting(siIndex: number){
        let signup = this.event.signupMeetings[siIndex];
        this.backendConnection.deleteRequest(
                this.userService.token, `/deleteDb?table=signup&id=${signup.id}`)
        .subscribe(() => { console.log("delete signup complete")});
        signup.requests.forEach( req => {
            this.backendConnection.deleteRequest(
                this.userService.token, `/deleteDb?table=signup_request&id=${req.id}`)
            .subscribe(() => { console.log("delete request complete")});
        });
        this.event.signupMeetings.splice(siIndex, 1);
    }

    public goToSignupMeeting(mid: number){
        if(!this.goldMine.setExplodesFromMid(mid)){
            this.dialog.open(SelectorDialogComponent,{
                data: {
                    selectorType: 'confirm',
                    message: '<p>Sorry, the approved meeting has been deleted from Goldmine.</p>'
                }
            });
        } else {
            this.router.navigate(['/splash/details']);
        }
    }

    /**
     * Changes the column `filled` to 'yes' of the signup event.
     * Essentially created a meeting that looks filled to the customer but really isn't
     * @param siIndex
     */
    public toggleFakeFilled(siIndex: number){
        let signup = this.event.signupMeetings[siIndex];
        this.backendConnection.postRequest(
                this.userService.token, `/updateDb`, {
        table: 'signup',
        data: [{
          filled: signup.filled == 'no'? 'fake' : 'no',
          id: signup.id
        }]
      })
        .subscribe(() => { 
            signup.filled = signup.filled == 'no'? 'fake' : 'no'
        });
    }

    public denySignup(siIndex: number, reqIndex: number){
        let signup = this.event.signupMeetings[siIndex];
        let req = signup.requests[reqIndex];
        this.backendConnection.deleteRequest(
                this.userService.token, `/deleteDb?table=signup_request&id=${req.id}`)
        .subscribe(() => { console.log("delete complete")});
        this.backendConnection.postRequest(
                this.userService.token, `/updateDb`, {
                    'table' : 'signup',
                    'data' : [{
                        filled : 'no',
                        id : signup.id
                    }]
                })
        .subscribe(() => { console.log("update complete")});
        signup.requests.splice(reqIndex, 1);
    }

    public approveSignup(siIndex: number, reqIndex: number){
        let meeting = this.event.signupMeetings[siIndex];
        let req = meeting.requests[reqIndex];
        let data = {
            id: req.id,
            signup_id: req.signup_id,
            email: req.gm_identifier,
            info: req.info,
            dateTime: meeting.dateTime,
            duration: meeting.dur,
            zoomRegisterLink: meeting.zoomRegisterLink,
            zoomJoinLink: meeting.zoomJoinLink,
            zoomId: meeting.zoomId,
            zoomPass: meeting.zoomPass
        };
        if(req.master){
            data['master'] = req.master;
        }
        let loaderDialogRef = this.dialog.open(LoaderDialogComponent, { panelClass: 'smaller-container-width'});
        this.backendConnection.postRequest(this.userService.token, '/approveSignupRequest', data).subscribe(
            (response) => {
                if(!response.status){
                    alert(response.message);
                    if(response.email_fault)
                        req.gm_identifier_not_found = 1;
                    loaderDialogRef.close();
                } else {
                    this.event.signupMeetings.splice(siIndex, 1);
                    loaderDialogRef.close();
                    this.dialog.open(SelectorDialogComponent, { data: {
                        message: `A Goldmine appointment was created, please refresh MM now`,
                        selectorType: 'confirmOnly'
                    }});
                }
            },  
            (err) => {
                console.log(err);
                loaderDialogRef.close();
            }
        );
    }

    public searchCompany(event: any, siIndex: number, reqIndex: number){
        let meeting = this.event.signupMeetings[siIndex];
        let req = meeting.requests[reqIndex];
        let input = event.target.value;

        if (input.length > 3) {
            this.backendConnection.getRequest(this.userService.token, 
                `/searchCompanies?company=${input}`).subscribe(
                    (response: any) => {
                        if(response.status)
                            req.searchResults = response.data;
                    }, (err) => console.log(err)
                )
        }
    }

    public addMasterRecord(siIndex: number, reqIndex: number, searchIndex: number){
        let meeting = this.event.signupMeetings[siIndex];
        let req = meeting.requests[reqIndex];
        let result = req.searchResults[searchIndex];
        req['master'] = result;
        req.companyName = result.COMPANY;
        req.gm_identifier_not_found = 0; //temp, will reset on reload.
        req.searchResults = [];
        console.log(this.event);
    }

    public addSignupMeeting(){
        let date = new Date(this.signupStart);
        if(isNaN(date.getTime())){
            alert("That's not a valid date");
            return;
        }
        for(let x = 0; x < this.signupCount; x++){
            this.backendConnection.postRequest(this.userService.token, '/insertSignupSlot', {
                dateTime: date.toISOString(),
                duration: 60
            }).subscribe(
                (res) => {
                    console.log(res);
                }
            );
            date.setMinutes( date.getMinutes() + 60);
        }
    }

    dateToStringMonthDay(date: Date): string {
        return this.utilities.dateToStringMonthDay(date);
    }

    ngOnDestroy(){
        this.subs.forEach(sub => sub.unsubscribe());
    }

}
