import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-phone-select',
  templateUrl: './phone-select.component.html',
  styleUrls: ['./phone-select.component.scss']
})
export class PhoneSelectComponent{

  constructor(
    public dialogRef: MatDialogRef<PhoneSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) 
  {}

  onNoClick(){
    this.dialogRef.close();
  }

}
