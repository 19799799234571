import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { GoldmineService } from '@app/core/services/goldmine.service';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { Meeting } from '@app/core/models/goldmine.model';
import { Config } from '@app/core/config';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class SplashComponent implements OnInit{
  

  public innerHeight: number;
  public currentRoute: string;

  public meeting: Meeting;

    //Need a way of keeping track which version of code their client is running.
  public version: string;


  constructor(
    public userService: UserService,
    public goldMine: GoldmineService,
    public utilities: UtilitiesService,
    private location: Location,
    private router: Router,
    private cd: ChangeDetectorRef,
    private config: Config
  ) { 
    router.events.subscribe(() => {
      if(location.path() != ''){
        this.currentRoute = location.path();
      }
    });
    this.version = this.config.version;
  }

  ngOnInit() {
    this.innerHeight = window.innerHeight;
    this.userService._init();
    //no reason to go to splash directly
    if (this.currentRoute == '/splash'){
        console.log("rerouting attempt");
      this.router.navigate(['/splash/planner']);
    }
    //listen to meetingupdate subject to get the current meeting
    this.goldMine.updateMeetingSubject.subscribe(
      (res: Meeting) => { 
        this.meeting = res;
        this.cd.detectChanges();
      }
    )
  }

  onResize(event){
    this.innerHeight = event.target.innerHeight; // window width
  }

  get user(){
    return this.userService.user;
  }


}
