import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
//MODELS
import { Meeting } from '../models/goldmine.model';
import { EmailAttachment, EmailCan, EmailAction, EmailTemp } from '../models/email.model';
//COMPONENTS AND SERVICES
import { UserService } from './user.service';
import { BackendConnectionService } from './backend.connection.service';
import { GoldmineService } from './goldmine.service';
import { UtilitiesService } from './utilities.service';
import { LoaderDialogComponent } from '@app/modules/loader-dialog/loader-dialog.component';
import { MatDialog } from '@angular/material';
import { SelectorDialogComponent } from '@app/modules/selector-dialog/selector-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PostEmailService implements OnDestroy {

  public emailActions: EmailAction[] = [];
  public cusPreEmails: EmailCan[] = [];
  public cusPostEmails: EmailCan[] = [];
  public emailActionSubject: Subject<any> = new Subject;

  public emailAttachments: EmailAttachment[] = [];
  public preEmailCannedMsgs: EmailCan[] = [];
  public postEmailCannedMsgs: EmailCan[] = [];
  public postEmailTradeShowCannedMsgs: EmailCan[] = [];
  public allEmails: any = {}; //Store all the EmailCan's in a big object to be retrieved quickly.

  public postEmailServiceSubject: Subject<any> = new Subject;
  public postEmailServiceLoaded: boolean = false;
  private lastMeetingIndex: number;

  private subs: Subscription[] = [];

  constructor(
    private backendService: BackendConnectionService,
    private userService: UserService,
    private goldMine: GoldmineService,
    private utilities: UtilitiesService,
    private dialog: MatDialog
  ) {
    console.log("Email service created");
    if(this.goldMine.meetingsLoaded){
      this.getEmailAttachments(); //Canned email messages will be chained
    } else {
      this.subs.push(this.goldMine.meetingsSubject.subscribe(
        () => { this.getEmailAttachments(); }
      ));
    }
  }

  public getEmailActions(meetingId: number){
    if(meetingId !== this.lastMeetingIndex){
      this.backendService.getRequest(this.userService.token,
        `/fetchDb?select=email_temp&table=meetings&column=mid&value=${meetingId}`)
        .subscribe(
          (res: any[]) => {
            if(res[0].email_temp !== 'null' && res[0].email_temp !== '(NULL)' 
              && res[0].email_temp !== null && res[0].email_temp !== [] && 
              res[0].email_temp !== '[]'){
              let data: EmailTemp = JSON.parse(res[0].email_temp);
                this.emailActions = data.emailActions;
                this.cusPreEmails = data.cusPreEmails;
                this.cusPostEmails = data.cusPostEmails;
                //Push any custom emails onto the list of available canned messages
                data.cusPreEmails.forEach( msg => {
                    msg.emid = Number(msg.emid);
                    this.preEmailCannedMsgs.push(msg)
                    this.allEmails[msg.emid] = msg;
                });
                data.cusPostEmails.forEach( msg => {
                    msg.emid = Number(msg.emid);
                    this.postEmailCannedMsgs.push(msg);
                    this.postEmailTradeShowCannedMsgs.push(msg);
                    this.allEmails[msg.emid] = msg;
                });
            }
            else this.emailActions = [];
          },
          (err) => { console.log(err); },
          () => {
            this.lastMeetingIndex = meetingId;
            this.emailActionSubject.next();
          }
        )
    } else {
      this.emailActionSubject.next();
    }
  }

  /**
   * SAVE AN ARRAY OF FOLLOWUP MODULES TO "action_temp"
   * @param meetingId 
   */
  public saveActions(meetingId: number){
    let tempActions = JSON.parse(JSON.stringify(this.emailActions));
    tempActions.forEach( p => {
      delete p.allAttachments;
      delete p.preEmail.name;
      delete p.postEmail.name;
    });
    this.backendService.postRequest(this.userService.token, 
      '/updateDb', {
        table: 'meetings',
        data: [{
          email_temp: JSON.stringify({
            cusPreEmails: this.cusPreEmails,
            cusPostEmails: this.cusPostEmails,
            emailActions: tempActions,
          }),
          mid: meetingId
        }]
      }).subscribe(
        (res) => console.log(res),
        (err) => {
          console.log(err);
          alert("Warning, changes may not have been saved!\nPlease check your connection!\n" 
          + "Error:" +  err);
        }
      )
  }

  public sendPreMeetingEmail(meeting: Meeting){
    //Have to remove unecessary fields from the array
    let tempActions = JSON.parse(JSON.stringify(this.emailActions));
    tempActions.forEach( p => {
      delete p.allAttachments;
    });
    let loaderDialogRef = this.dialog.open(LoaderDialogComponent, { panelClass: 'smaller-container-width'});
    this.backendService.postRequest(this.userService.token,
      '/sendPreEmails', {
        appData: {
          appDate: this.utilities.dateToStringDay(this.goldMine.weekday.convertDate, true),
          appTime: this.utilities.formatTime(meeting.ontime),
          company: meeting.company
        },
        cusPreEmails: this.cusPreEmails,
        emailActions: tempActions
      } ).subscribe(
        () => {},
        (err) => {
          console.log(err);
          alert(`
            Warning, something went wrong while sending pre emails!
            \nPlease check your connection, and refresh your browser.
            \nError: ${err}
          `);
          loaderDialogRef.close();
        },
        () => {
          this.emailActions.forEach((action) => {
            if(action.hasEmail && !action.onHold){
              action.preEmail.isSent = true;
            }
          });
          loaderDialogRef.close();
        }
      );
  }

  public sendPostMeetingEmail(meeting: Meeting){
    //Have to remove unecessary fields from the array
    let tempActions = JSON.parse(JSON.stringify(this.emailActions));
    tempActions.forEach( p => {
      delete p.allAttachments;
    });
    let loaderDialogRef = this.dialog.open(LoaderDialogComponent, { panelClass: 'smaller-container-width'});
    this.backendService.postRequest(this.userService.token,
      '/sendPostEmails', {
        appData: {
          appDate: this.utilities.dateToStringDay(this.goldMine.weekday.convertDate, true),
          appTime: this.utilities.formatTime(meeting.ontime),
          company: meeting.company,
          reference: meeting.ref
        },
        cusPostEmails: this.cusPostEmails,
        emailActions: tempActions
      } ).subscribe(
        (res) => {
            loaderDialogRef.close();
            if(res.status && res.data.faults){
                let faultHTML = ``;
                Object.keys(res.data.faults).forEach((aidIndex) => {
                    let fault = res.data.faults[aidIndex];
                    faultHTML += `<li>${fault.name} => ${fault.message}</li>`;
                });
                this.dialog.open(SelectorDialogComponent, {
                    data: {
                        message : `
                        <h5>The follow emails didn't send properly!!:</h5>
                        <ul>${faultHTML}</ul>
                        `
                    }
                });
            }
            if(res.status){
                this.emailActions.forEach((action) => {
                    if(action.hasEmail && !action.onHold && !res.data.faults.hasOwnProperty(action.aid)){
                        action.postEmail.isSent = true;
                    }
                });
            }
        },
        (err) => {
          console.log(err);
          alert(`Warning, something went wrong while sending post emails!
            \nError: ${err}
          `);
          loaderDialogRef.close();
        },
        () => {
          
          loaderDialogRef.close();
        }
      );
  }

  // (2)
  public getEmailCannedMessages(){
    this.backendService.getRequest(this.userService.token,
      '/getEmails')
      .subscribe(
        (res: EmailCan[]) => {
          res.forEach( (msg) => {
            msg.emid = Number(msg.emid);
            if(msg.area == 'post' || msg.area == 'missed'){
              this.postEmailCannedMsgs.push(msg);
            } else if (msg.area == 'pre'){
              this.preEmailCannedMsgs.push(msg);
            } else if (msg.area == 'show'){
                this.postEmailTradeShowCannedMsgs.push(msg);
            }
            this.allEmails[msg.emid] = msg;
          });
        },
        (err) => { console.log(err)},
        () => {
          this.postEmailServiceLoaded = true;
          this.postEmailServiceSubject.next();
        }
      );
  }

  // (1)
  public getEmailAttachments(){
    this.backendService.getRequest(this.userService.token,
      '/getActions?type=attachments'
    ).subscribe(
      (res: EmailAttachment[]) => {
        this.emailAttachments = res;
        this.emailAttachments.forEach(att => {
          att.acid = Number(att.acid);
          att.is_default = Boolean(+att.is_default);
          att.is_territory = Boolean(+att.is_territory);
        });
      },
      (err) => console.log(err),
      () => {
        this.getEmailCannedMessages(); //Chained
      }
    );
  }

  ngOnDestroy(){
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
