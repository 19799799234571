import { 
  ComponentFactoryResolver, 
  Component, 
  OnInit, 
  OnDestroy,
  ViewContainerRef, 
  ViewChild, 
  ComponentFactory 
} from '@angular/core';
import { Subscription } from 'rxjs';
//MODELS
import { Meeting } from '@app/core/models/goldmine.model';
import { Attendee } from '@app/core/models/attendees.model';
import { FollowupModule, FollowupMenu } from '@app/core/models/followup.model';
//COMPONENTS AND SERVICES
import { GoldmineService } from '@app/core/services/goldmine.service';
import { FollowupService } from '@app/core/services/followup.service';
import { CatalogComponent } from './modules/catalog.module.component';
import { AttendeesService } from '@app/core/services/attendees.service';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { ComponentCanDeactivate } from '@app/modules/auth/can-deactivate/component-can-deactivate';

//this is a working example of dynamic nested elements in a mat menu
//https://stackblitz.com/edit/dynamic-nested-menus?file=app%2Fapp.component.html

//this is a working example of dynamic templates for angular
//https://stackblitz.com/angular/vvnllgyxxrl

//Reactive forms using child Components
//https://medium.com/@joshblf/using-child-components-in-angular-forms-d44e60036664


@Component({
  selector: 'app-followup',
  templateUrl: './followup.component.html',
  styleUrls: ['./followup.component.scss']
})
export class FollowupComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  @ViewChild('dynamic', { read: ViewContainerRef }) container: ViewContainerRef;

  public attendees: Attendee[];
  private attendeeSubscription: Subscription;

  public meeting: Meeting;
  
  /** An array of potential followup actions */
  public template: FollowupMenu[];
  public templateSubscription: Subscription;

  /** An array of actions that have already been selected and filld out */
  public actionsToPerform: FollowupModule[];
  private actionsToPerformSubscription: Subscription;

  /**An array of subscriptions to any modules that have been pushed into the view */
  private childSubscriptions: Subscription[] = [];

  //list of action categories that have their own sub category
  public excludeList = [
    'catalog',
    'digital-catalog',
    'sample',
    'concept-request',
    'collateral'
  ];
  
  constructor(
    private goldMine: GoldmineService,
    private followupService: FollowupService,
    private attendeeService: AttendeesService,
    private utilities: UtilitiesService,
    private factoryResolver: ComponentFactoryResolver
  ) {
    super();
  }

  //HIGHEST LEVEL OF DATA SERVICES = FOLLOWUP
  ngOnInit() {
    if(this.followupService.templateLoaded){
      this._init();
    } else {
      this.templateSubscription = this.followupService.templateSubject.subscribe(
        () => {
          this._init()
        }
      );
    }
  }

  private _init(): void{
    this.meeting = this.goldMine.meeting;
    this.template = this.followupService.template;
    this.attendeeSubscription = this.attendeeService.attendeesSubject.subscribe(
      (res: Attendee[]) => {
        this.attendees = res;
        this.followupService.getActions(this.meeting.mid); //Need attendees to load before Actions
      }
    );
    this.actionsToPerformSubscription = this.followupService.actionsToPerformSubject.subscribe(
      () => {
        this.actionsToPerform = this.followupService.actionsToPerform;
        this._initModules();
      }
    )
    this.attendeeService.getAttendees(this.meeting.mid);
  }

  /**
   * LOAD RELEVANT MODULES BACK INTO THE CONTAINER FROM THE DATABASE
   */
  private _initModules(){
    if(this.actionsToPerform.length > 0){
      this.actionsToPerform.forEach((action, actionIndex) => {
        /**
         * Need to update the colors on the list of attendees for each action.
         * Also if the attendee was removed or "unconfirmed" remove them from the recipients array
         */
        action.recipients.forEach((person, personIndex) => {
          let found = this.attendees.find(p => p.aid == person.aid);
          if(found){
            person.color = found.color;
          } else{
            action.recipients.splice(personIndex, 1);
          }
        });


        let templateIndex = this.findTemplateIndexById(action.acid);
        if(templateIndex !== -1){
          let factory;
          switch(action.category){
            case 'followup':
              //factory = this.factoryResolver.resolveComponentFactory()
              break;
            case 'quote':
              //factory = this.factoryResolver.resolveComponentFactory()
              break;
            default:
              factory = this.factoryResolver.resolveComponentFactory(CatalogComponent);
              break;
          }
          this.insertNewModule(actionIndex, factory);
        } else {
          console.log("No template index found for: " + JSON.stringify(action) + "Perhaps it was removed!");
        }
      });
    }
  }


  private findTemplateIndexByName(name: string): number{
    return this.template.findIndex((item) => {
      return item.category == name
    });
  }

  private findTemplateIndexById(actionId: number): number{
    return this.template.findIndex((item) => {
      return item.acid == actionId;
    });
  }

  private findActionIndexById(uniqueId: string): number {
    return this.actionsToPerform.findIndex((item) => {
      return item.uniqueId == uniqueId;
    });
  }

  public insertModuleByName(name: string){
    this.insertModuleByIndex(
      this.findTemplateIndexByName(name)
    );
  }
  
  public insertModuleByIndex(templateIndex: number){
    //create a new FollowupModule and insert it into the actions to be performed
    let findTemplate = new FollowupModule(
      JSON.parse(JSON.stringify(this.template[templateIndex].subActions)),
      this.template[templateIndex].category,
      this.template[templateIndex].acid,
      this.utilities.randString(10)
    );
    let actionIndex = this.actionsToPerform.push(findTemplate) - 1;
    this.insertNewModule(actionIndex);
  }
  
  /**
   * APPENDS A NEW MODULE ONTO THE CURRENT VIEW SUBSCRIBES
   * TO ANY EVENTS THAT ARE EMITED BY SUCH MODULE
   * @param actionIndex 
   * @param factory 
   */
  private insertNewModule( actionIndex: number,
    factory: ComponentFactory<any> = this.factoryResolver.resolveComponentFactory(CatalogComponent)
  ){
    const component = factory.create(this.container.parentInjector);
    const viewRef = this.container.insert(component.hostView, this.container.length);

    component.instance.index = actionIndex;
    component.instance.attendees = this.attendees;
    component.instance.self = viewRef;
    
    this.childSubscriptions.push(component.instance.listener.subscribe(
      //listen to method requests from 
      (data) => {
        switch(data.method){
          case 'appendOnly':
            this.appendOnly(data.uniqueId);
            break;
          case 'removeSelf':
            this.container.remove(this.container.indexOf(data.viewRef));
            this.actionsToPerform.splice(this.findActionIndexById(data.uniqueId), 1);
            break;
          case 'sendSelf':
              this.followupService.saveActions(this.meeting.mid);
            this.followupService.sendAction(
              this.findActionIndexById(data.uniqueId), this.meeting.mid);
            break;
        }
      }
    ));
  }

  /**
   * CALLED FROM A MODULE VIA "listener"
   * MODULE WILL BE SET TO TOGGLE = TRUE
   * EVERY OTHER MODULE WILL BE SET TO TOGGLE = FALSE
   * @param index
   */
  private appendOnly(uniqueId: string){
    let index = this.findActionIndexById(uniqueId);
    this.actionsToPerform.forEach((actionItem, actionIndex) =>{
      if(index != actionIndex){
        actionItem.toggle = false;
      } else {
        actionItem.toggle = true;
      }
    });
  }

  /**
   * PUSHES A NEW RECIPIENT ONTO THE TEMPLATE OBJECT IF TOGGLE = TRUE
   * @param index 
   */
  public addActionRecipients(index: number){
    let attendee = this.attendees[index];
    this.actionsToPerform.forEach((action) => {
      if(action.toggle && !action.isComplete){
        if(!action.recipients.find((person) => { return person.aid == attendee.aid})){
          action.recipients.push({
            aid: attendee.aid,
            name: attendee.full_name,
            color: attendee.color
          });
        }
      }
    });
  }

  /**
   * PUSHES ALL ATTENDEES ONTO THE TEMPLATE OBJECT IF TOGGLE = TRUE
   */
  public addActionRecipientsAll(){
    this.actionsToPerform.forEach((action) => {
      if(action.toggle && !action.isComplete){
        action.recipients.length = 0; //clear out any previous recipients
        this.attendees.forEach((person) => {
            action.recipients.push({
              aid: person.aid,
              name: person.full_name,
              color: person.color
            });
        });
      }
    });
  }

  private _destroy(){
    this.followupService.saveActions(this.meeting.mid);
    //unsubs
    if(this.templateSubscription) this.templateSubscription.unsubscribe();
    this.actionsToPerformSubscription.unsubscribe();
    this.attendeeSubscription.unsubscribe();
    this.childSubscriptions.forEach(sub => sub.unsubscribe);
  }

  ngOnDestroy(){
    this._destroy();
  }
 
  canDeactivate(){
    return false;
  }
}
