import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material';
//MODELS
import { Attendee } from '@app/core/models/attendees.model';
import { Meeting } from '@app/core/models/goldmine.model';
import { EmailAction, EmailAttachment, EmailCan } from '@app/core/models/email.model';
//COMPONENTS AND SERVICES
import { ComponentCanDeactivate } from '@app/modules/auth/can-deactivate/component-can-deactivate';
import { SelectorDialogComponent } from '@app/modules/selector-dialog/selector-dialog.component';
import { EmailDialogComponent } from './email-dialog/email-dialog.component';
import { GMAppUpdateComponent } from '../gm-update-dialogs/gm-app-update.component';
import { PostEmailService } from '@app/core/services/post-email.service';
import { AttendeesService } from '@app/core/services/attendees.service';
import { GoldmineService } from '@app/core/services/goldmine.service';
import { GMContactUpdateComponent } from '../gm-update-dialogs/gm-contact-update.component';

@Component({
  selector: 'app-post-email',
  templateUrl: './post-email.component.html',
  styleUrls: ['./post-email.component.scss']
})
export class PostEmailComponent extends ComponentCanDeactivate implements OnInit{

  public meeting: Meeting;
  public attendees: Attendee[];

  public emailAttachments: EmailAttachment[];
  public preEmailCannedMsgs: EmailCan[];
  public postEmailCannedMsgs: EmailCan[];
  public postEmailMissedCannedMsgs: EmailCan[];
  public emailActions: EmailAction[] = [];

  private subs: Subscription[] = [];

  private defaultPre: EmailCan;
  private defaultConfirmed: EmailCan;
  private defaultMissed: EmailCan;

  private allEmailsToggled = true; //wether or not toggleHoldAllEmails has been run yet.

  //HIGHEST LEVEL OF DATA = EMAIL
  constructor(
    private goldMine: GoldmineService,
    private pEService: PostEmailService,
    private attendeeService: AttendeesService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    if(this.pEService.postEmailServiceLoaded){
      this._init();
    } else {
      this.subs.push(this.pEService.postEmailServiceSubject.subscribe(
        () => { this._init(); }
      ));
    }
  }

  private _init(): void{
    this.meeting = this.goldMine.meeting
    this.emailAttachments = this.pEService.emailAttachments;
    this.preEmailCannedMsgs = this.pEService.preEmailCannedMsgs;
    this.postEmailCannedMsgs = this.meeting.isTradeShow 
        ? this.pEService.postEmailTradeShowCannedMsgs 
        : this.pEService.postEmailCannedMsgs;
    //Get attendees & Get email actions
    this.subs.push(this.attendeeService.attendeesSubject.subscribe(
      (res) => {
        this.attendees = res;
        //Get email actions -> CHAINED
        this.subs.push(this.pEService.emailActionSubject.subscribe(
          () => { 
            this.buildEmailActions();
          }
        ));
        this.pEService.getEmailActions(this.meeting.mid);
       }
    ));
    this.attendeeService.getAttendees(this.meeting.mid);
  }

  /**
   * ITERATES THROUGH THE EMAIL ACTIONS AND THE ATTENDEES TO SYNC 
   * BOTH OF THE ARRAYS TO EACH OTHER.
   * ALSO SETS THE DEFUALT CANNED EMAIL FOR EACH ATTENDEE
   */
  private buildEmailActions(){
    this.emailActions = this.pEService.emailActions;

    let defaultPre = this.preEmailCannedMsgs.find(msg => {
        return msg.user_default == '1'
    });
    let defaultConfirmed = this.postEmailCannedMsgs.find( ({area, user_default, is_default}) => {
        if(this.meeting.isTradeShow)
            return area == 'show' && is_default == '1';
        else
            return area == 'post' && user_default == '1';

    });
    let defaultMissed = this.postEmailCannedMsgs.find(({area, user_default}) => {
        if(this.meeting.isTradeShow) return true;
        return user_default == '1' && area == 'missed';
    });

    //If default emails were not found just default to the first one in each category.
    if(!defaultPre) defaultPre = this.preEmailCannedMsgs[0];
    if(!defaultConfirmed) defaultConfirmed = this.postEmailCannedMsgs[0];
    if(!defaultMissed) defaultMissed = this.postEmailCannedMsgs[0];

    this.defaultPre = defaultPre;
    this.defaultConfirmed = defaultConfirmed;
    this.defaultMissed = defaultMissed;

    //Build a list of GLOBAL default attachments.
    let defaultAttachments = [];
    this.emailAttachments.forEach(att => {
      if(att.is_default) defaultAttachments.push(att.acid);
    });


    if(this.emailActions.length > 0){
      //---------------------------------------------------------------
      //Need to compare attendees to emailActions to add and update list
        let setDefaultPostEmails = (found:EmailAction, isConfirmed: number ) => {
            //---- Setting email name helper function -----
            if(isConfirmed == 1){
              found.postEmail.emid = defaultConfirmed.emid;
              found.postEmail.name = defaultConfirmed.name;
            } else{
              found.postEmail.emid = defaultMissed.emid;
              found.postEmail.name = defaultMissed.name;
            }
        };
      this.attendees.forEach((person, index) => {
        let found = this.emailActions.find(a => a.aid == person.aid);
        if(!found){
          this.addAttendee(person, index, defaultAttachments, defaultPre, defaultConfirmed, defaultMissed);
        } else {
          /**
           * Because attendees and emails are not linked we'll we need to check
           * if there were any changes to the Array index or the confirmed status
           * of each attendee
           */
          if(found.index !== index) found.index = index; //Need to store the new index
          if(person.email) found.hasEmail = true;
          if(person.sent_pre_email) found.preEmail.isSent = true;
          if(person.sent_post_email) found.postEmail.isSent = true;
          if(person.mark_email_change){
            setDefaultPostEmails(found, person.is_confirmed);
            person.mark_email_change = 0;
            person.touched = true;
          } else {
              //let's get the name of the email now if it exists, otherwise default
              //the email if for some reason it was deleted.
              if(this.pEService.allEmails[found.postEmail.emid]){
                  found.postEmail.name = this.pEService.allEmails[found.postEmail.emid].name;
              } else {
                setDefaultPostEmails(found, person.is_confirmed);
              }
              //do the same for pre emails.
              if(this.pEService.allEmails[found.preEmail.emid]){
                found.preEmail.name = this.pEService.allEmails[found.preEmail.emid].name;
              } else {
                found.preEmail.emid = defaultPre.emid;
                found.preEmail.name = defaultPre.name;
              }
          }
        }
      });
      //-----------------------------------------------------------------
      //Then we need to compare emailActions to attendees to remove
      let removeList = [];
      this.emailActions.forEach((person, index) => {
        let found = this.attendees.find(a => a.aid == person.aid);
        if(!found){
          removeList.push(index);
        }
      });
      for (let index = removeList.length - 1; index >= 0; index--) {
        this.emailActions.splice(removeList[index], 1);
      }
    } else { //Init a new meeting
      this.attendees.forEach((person, index) => {
        this.addAttendee(person, index, defaultAttachments, defaultPre, defaultConfirmed, defaultMissed);
      });
    }
    /**
     * For each person in emailActions we need to push an array of possible attachments
     * and check it agains attachments already made.
     * the allAttachments array will be deleted before the array is saved
     * to ensure a fresh list of attachments is getting pulled in and reviewed.
     * This also prevents us from having to check in real time.
     * 
     * TODO??, this is not checking if any attachments have been removed from the database.
     */
    this.emailActions.forEach(( person ) => {
      person.allAttachments = [];
      this.emailAttachments.forEach(att => {
        let attached = Boolean(person.attachments.find( a => a == att.acid));
        person.allAttachments.push({
          acid: att.acid,
          name: att.name,
          description: att.description,
          action_type: att.action_type,
          isAttached: attached
        });
      });
    });
  }

  /**
   * Adds someone from the attendees array onto the array of emailActions
   * @param person 
   * @param index 
   * @param attachments 
   * @param defaultPre 
   * @param defaultConfirmed 
   * @param defaultMissed 
   */
  private addAttendee(person: Attendee, index: number, attachments: number[],
    defaultPre: EmailCan, defaultConfirmed: EmailCan, defaultMissed: EmailCan): void{
    this.emailActions.push(new EmailAction(
      person.aid,
      index,
      defaultPre,
      person.sent_pre_email,
      person.sent_post_email,
      //Has Email Function
      () => {
        if (person.email) return true;
        return false;
      },
      //Default post email function
      ()=>{ 
        if(person.is_confirmed) return defaultConfirmed;
        return defaultMissed;
      },
      //Populate Attachment Id array function
      () => {
        let att = JSON.parse(JSON.stringify(attachments)); //List of GLOBAL default.
        if(this.emailAttachments.findIndex((a) => {
            //Territory bypassed on tradeshows.
            return a.acid === person.service_team_flyer && (a.is_territory || this.meeting.isTradeShow);
        }) !== -1){
          att.push( person.service_team_flyer );
        }
        return att;
      }
    ));
  }

  /**
   * Opene the Email Dialog to allow for email customization.
   * If person does not have email the GMUpdate Dialog will be opened instead.
   * @param person 
   */
  public openEmailDialog(person: EmailAction, tabSelect: number){
    let dialogRef;
    if(this.attendees[person.index].email){
      dialogRef = this.dialog.open(EmailDialogComponent, {
        data: {
          persons: [person],
          preEmailCannedMsgs: this.preEmailCannedMsgs,
          postEmailCannedMsgs: this.postEmailCannedMsgs,
          defaultPreEmailEmid: this.defaultPre.emid, //not used here, in this context
        defaultPostEmailEmid: this.defaultConfirmed.emid, //not used here, in this context
          tabSelect: tabSelect,
          isTradeShow: this.meeting.isTradeShow,
          actvcode: this.meeting.actvcode
        }
      });
    } else {
      dialogRef = this.dialog.open(GMContactUpdateComponent, {
        data: {
          attendees: this.attendees,
          index: person.index
        }
      });
    }
    dialogRef.afterClosed().subscribe(()=>{
      this.pEService.saveActions(this.meeting.mid);
    });
  }

  public openEmailDialogAll(presentMissed: string){
      let persons = this.emailActions.filter(action => {
          let confirmed = this.attendees[action.index].is_confirmed;
          if (confirmed == 1 && presentMissed == 'present')
              return true;
          if (confirmed == 0 && presentMissed == 'missed')
              return true;
          return false;
      });
      let dialogRef = this.dialog.open(EmailDialogComponent, {
        data: {
          persons: persons,
          preEmailCannedMsgs: this.preEmailCannedMsgs,
          postEmailCannedMsgs: this.postEmailCannedMsgs,
          defaultPreEmailEmid: this.defaultPre.emid,
            defaultPostEmailEmid: this.defaultConfirmed.emid,
          tabSelect: 1,
          isTradeShow: this.meeting.isTradeShow,
          actvcode: this.meeting.actvcode
        }
      });
    dialogRef.afterClosed().subscribe(()=>{
      this.pEService.saveActions(this.meeting.mid);
    });
  }

    /**
     * Loop through the email actions and attach the necessary attchment where necessary.
     * @param attachment 
     * @param presentMissed 
     */
  public attachToAll(attachment: any, presentMissed: string){
      for(let x = 0; x < this.emailActions.length; x++){
          let person = this.emailActions[x];
          if(this.attendees[person.index].is_confirmed == 1 && presentMissed == 'missed')
            continue;
          if(this.attendees[person.index].is_confirmed == 0 && presentMissed == 'present')
            continue;
          if(!person.attachments.includes(attachment.acid)){
              person.attachments.push(attachment.acid);
              person.allAttachments.find( a => a.acid == attachment.acid).isAttached = true;
          }
      }
  }

  public toggleAttachment(event, attachment: any, person: EmailAction){
    event.stopPropagation(); //prevent the window from shutting;
    if(attachment.isAttached){
      attachment.isAttached = false;
      person.attachments.splice(person.attachments.findIndex(a => a == attachment.acid), 1);
    } else {
      attachment.isAttached = true;
      person.attachments.push(attachment.acid);
    }
  }

  public addAllAttachments(person: EmailAction){
    person.attachments = [];
    person.allAttachments.forEach(element => {
      person.attachments.push(element.acid);
      element.isAttached = true;
    });
  }

  public clearAttachmentsAll(){
      let dialogRef = this.dialog.open(SelectorDialogComponent, {
      data: {
        selectorType: 'yesNo',
        message: this.getNoEmailHTML() +
      `<p>Are you sure you want to remove all attachments from every attendee?
      This will include attendees present and attendees missed.</p>`
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: boolean) => {
        if(res){
          this.emailActions.forEach( person => {
              person.attachments = [];
              person.allAttachments.forEach( a => {
                  a.isAttached = false;
              });
          });
        }
      }
    );
  }

  public sendPreMeetingEmail(){
    let dialogRef = this.dialog.open(SelectorDialogComponent, {
      data: {
        selectorType: 'yesNo',
        message: this.getNoEmailHTML() +
      `<p>Are you sure you want to send a <i style='color: red;'>PRE</i> meeting email to all attendees?</p>`
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: boolean) => {
        if(res){
          this.pEService.sendPreMeetingEmail(this.meeting);
        }
      }
    );
  }

  public sendPostMeetingEmail(){
    let dialogRef = this.dialog.open(SelectorDialogComponent, {
      data: {
        selectorType: 'yesNo',
        message: this.getNoEmailHTML() +
      `<p>Are you sure you want to send a <i style='color: red;'>POST</i> meeting email to all attendees?</p>`
      }
    })
    dialogRef.afterClosed().subscribe(
      (res: boolean) => {
        if(res){
          this.pEService.sendPostMeetingEmail(this.meeting);
        }
      }
    );
  }

  private getNoEmailHTML(): string {
    let noEmailAttendees = "";
    this.attendees.forEach((person, index) => {
      if(!person.email){
        if(noEmailAttendees == "") noEmailAttendees += `
          <p>The following do not have emails saved</p>
          <div style='max-width: 250px; display: inline-block;'>
          <ul>`;
        noEmailAttendees += "<li>" + person.full_name + "</li>";
        if(index == this.attendees.length -1) noEmailAttendees += "</ul></div>";
      }
    });
    return noEmailAttendees;
  }

  /**
   * Set onHold = true for all attendees, unless this method has already been run. 
   * Then they are all set to false.
   */
  public toggleHoldAllEmails(): void{
    let toggleUntoggle = this.allEmailsToggled ? "hold" : "unhold";
    let dialogRef = this.dialog.open(SelectorDialogComponent, {
        data: {
          selectorType: 'yesNo',
          message: this.getNoEmailHTML() +
        `<p>Are you sure you want to ${toggleUntoggle} all emails?</p>`
        }
      });
      dialogRef.afterClosed().subscribe(
        (res: boolean) => {
          if(res){
            this.emailActions.forEach((person) => {
                if(this.allEmailsToggled){
                    person.onHold = true;
                } else {
                    person.onHold = false;
                }
            });
            this.allEmailsToggled = !this.allEmailsToggled;
          }
        }
      );
  }

  private _destroy(){
    this.subs.forEach(sub => sub.unsubscribe());
    this.pEService.saveActions(this.meeting.mid);
    this.attendeeService.updateAttendees();
  }

  ngOnDestroy(){
    this._destroy();
  }
  
  canDeactivate(){
    //TODO, check for changes in data
    return false;
  }

}
