import { Injectable } from '@angular/core'

/**
 * Heirachy of data services
 * 
 * 1. user service
 * 2. goldmine service
 * 3. followup service / email service
 */

@Injectable()
export class Config{
    //live
    // public readonly BASE_SERVER_URL = "https://meet.crystal-d.com/mmserver";
    // public readonly BASE_URL = "https://meet.crystal-d.com";
    //Dev
    public readonly BASE_SERVER_URL = "https://meetdev.crystal-d.com/mmserver";
    public readonly BASE_URL = "https://meetdev.crystal-d.com";

    public readonly version = '1.13';
}