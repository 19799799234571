import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
//MODELS
import { FollowupMenu, FollowupModule } from '../models/followup.model';
//COMPONENTS AND SERVICES
import { LoaderDialogComponent } from '@app/modules/loader-dialog/loader-dialog.component';
import { GoldmineService } from './goldmine.service';
import { UserService } from './user.service';
import { BackendConnectionService } from './backend.connection.service';

interface Groups{ //used for menu system
}

@Injectable({
  providedIn: 'root'
})
export class FollowupService implements OnDestroy {

  private lastMeetingIndex: number;

  public template: FollowupMenu[] = []; //transcribe follupservice results to this
  public templateSubject: Subject<any> = new Subject;
  public templateLoaded: boolean = false;
 
  public actionsToPerform: FollowupModule[] = [];
  public actionsToPerformSubject: Subject<any> = new Subject;

  public sendSingleActionSubject: Subject<any> = new Subject;

  public colors: string[];

  private subs: Subscription[] = [];

  constructor(
    private userService: UserService,
    private goldMine: GoldmineService,
    private backendService: BackendConnectionService,
    private dialog: MatDialog
  ) {
    console.log("Followup service created");
    if(this.goldMine.meetingsLoaded){
      this._initTempate();
    } else {
      this.subs.push(this.goldMine.meetingsSubject.subscribe(
        () => this._initTempate()
      ));
    }
  }

  private _initTempate(){
    this.backendService.getRequest<any>(this.userService.token, 
      '/getActions?type=internal')
      .subscribe(
        (res)=>{
          if(!res || res == null || res == 'null') console.log("Error, no response: " + res);
          this._buildTemplate(res);
        },
        (err)=>{ console.log(err) },
        ()=>{
          this.templateLoaded = true;
          this.templateSubject.next(); //load event
        }
      );
  }

    /**
   * TRANSCRIBES THE FOLLOWUP SERVICE ACTION TEMPLATE TO AN ARRAY OF FOLLOWUPMENU MODELS
   */
  private _buildTemplate(data: any[]){
    //build the groups of actions
    let groups: Groups = {
      catalog: [],
      digitalCatalog: [],
      collateral: []
    }
    data.forEach(
      (actionItem) => {
        actionItem.fields = JSON.parse(actionItem.fields);
        actionItem.acid = Number(actionItem.acid);
        actionItem.fields.forEach((field) => {
          //Need to add some data types to respective fields
          switch(field.type){
            case "txt": 
            case "textarea": 
            case "radio":
            case "radio-vert":
            case "check-list":
            case "check-list-vert":
            case "check":
            case "txt-num":
            case "hidden":
            case "dropdown":
              break;
            case "date-time":
              let date = new Date();
              field.data = date.toISOString().slice(0,16);
              break;
            default:
              console.log("Warning! Could not find field type " + 
                field.type + " for " + actionItem.acid);
              break;
          }
        });
        switch(actionItem.menu_category){
          default: //Not using groups as of yet
            let template = new FollowupMenu();
            template.category = actionItem.menu_category;
            template.acid = Number(actionItem.acid);
            template.subActions.push(actionItem);
            this.template.push(template);
            break;
        }
      }
    );
    for(let key in groups){ //Used with groups
      if(groups[key].length > 0){
        let template = new FollowupMenu();
        template.category = key;
        template.acid = groups[key].acid;
        template.subActions = groups[key];
        this.template.push(template);
      }
    }
  }

  /**
   * SAVE AN ARRAY OF FOLLOWUP MODULES TO "action_temp"
   * @param meetingId 
   */
  public saveActions(meetingId: number){
    this.backendService.postRequest(this.userService.token, 
      '/updateDb', {
        table: 'meetings',
        data: [{
          action_temp: JSON.stringify(this.actionsToPerform),
          mid: meetingId
        }]
      }).subscribe(
        (res) => console.log(res),
        (err) => {
          console.log(err);
          alert("Warning, changes may not have been saved!\nPlease check your connection!\n" 
          + "Error:" +  err);
        }
      )
  }

  /**
   * RETREIVE THE FOLLOWUP MODULES THAT HAVE BEEN SAVED TO "action_temp"
   * @param meetingId
   */
  public getActions(meetingId: number){
    if(meetingId !== this.lastMeetingIndex){
    this.backendService.getRequest(this.userService.token,
      `/fetchDb?select=action_temp&table=meetings&column=mid&value=${meetingId}`)
      .subscribe(
        (res: any) => {
          if(res[0].action_temp !== 'null' && res[0].action_temp !== '(NULL)' 
          && res[0].action_temp !== null && res[0].action_temp !== [] && 
          res[0].action_temp !== '[]'){
            this.actionsToPerform = JSON.parse(res[0].action_temp);
          }
          else this.actionsToPerform = [];
        },
        (err) => console.log(err),
        () => {
          this.lastMeetingIndex = meetingId;
          this.actionsToPerformSubject.next();
        }
      );
    } else {
      this.actionsToPerformSubject.next();
    }
  }

  /**
   * Finalize meeting call
   * @param meetingId 
   */
  public sendAction(actionIndex: number, meetingId: number){
    let action = this.actionsToPerform[actionIndex];
    if(action.acid == 9){
      alert("This Action Cannot be performed before finalizing appointment\nFinalize appointment to complete this action");
      return;
    }
    if(action.recipients.length == 0){
      alert("This action needs recipients before it's sent");
      return;
    }
    let loaderDialogRef = this.dialog.open(LoaderDialogComponent, { panelClass: 'smaller-container-width'});
    this.backendService.postRequest(this.userService.token,
      '/performActionFromPage', {
        action: JSON.stringify(action),
        mid: meetingId
      }).subscribe(
        (res: any) => {
          action['arids'] = res.arids || [];
        },
        (err) => {
          console.log(err)
        },
        () => {
          //Informs catalog component that a change has been made to one of the actions
          loaderDialogRef.close();
          action.isComplete = true;
          this.sendSingleActionSubject.next();
        }
      )
  }

  ngOnDestroy(){
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
