import { Component} from '@angular/core';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';
import { UserService } from '@app/core/services/user.service';
import { MatDialogRef } from '@angular/material';
import { UtilitiesService } from '@app/core/services/utilities.service';

@Component({
    selector: 'app-meeting-link-component',
    templateUrl: './meeting-link-component.component.html',
    styleUrls: ['./meeting-link-component.component.scss']
})
export class MeetingLinkComponentComponent {

    public meetings: any[];

    constructor(
        private backendService: BackendConnectionService,
        private userService: UserService,
        public utilities: UtilitiesService,
        public dialogRef: MatDialogRef<MeetingLinkComponentComponent>,
    ) {
        this.backendService.getRequest(this.userService.token, '/otherShows').subscribe(
            (res: any[]) => {
                res.forEach((show) => {
                    show.appt_date = this.utilities.dateToStringMonthDay(this.utilities.convertDate(show.appt_date));
                    show.mid = +show.mid;
                });
                this.meetings = res;
            },
            (err) => {
                console.log(err);
            }
        )
    }

    onNoClick() {
        this.dialogRef.close({
            linkID : 0,
            tsReference : null
        });
    }

    public closeDialog(linkID: number, tsReference: string, fullName: string) {
        this.dialogRef.close({
            linkID: linkID,
            tsReference : tsReference,
            fullName : fullName
        });
    }

}
