import { Component, OnInit, OnDestroy, NgZone, Inject } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
//MODELS
import { Meeting } from '@app/core/models/goldmine.model';
import { Attendee, LinkedAttendee } from '@app/core/models/attendees.model';
//COMOPNENTS AND SERVICES
import { AddContactComponent } from './add-contact/add-contact.component';
import { GoldmineService } from '@app/core/services/goldmine.service';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';
import { UserService } from '@app/core/services/user.service';
import { UtilitiesService } from '@app/core/services/utilities.service';
import { PhoneSelectComponent } from '../phone-select/phone-select.component';
import { AttendeesService } from '@app/core/services/attendees.service';
import { GMAppUpdateComponent } from '../gm-update-dialogs/gm-app-update.component';
import { ComponentCanDeactivate } from '@app/modules/auth/can-deactivate/component-can-deactivate';
import { GMContactUpdateComponent } from '../gm-update-dialogs/gm-contact-update.component';

declare let jsPDF;
import 'jspdf-autotable';
import { Router } from '@angular/router';

@Component({
  selector: 'app-attendees',
  templateUrl: './attendees.component.html',
  styleUrls: ['./attendees.component.scss'],
  providers: [
    { provide: 'Window',  useValue: window }
  ]
})
export class AttendeesComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  public meeting: Meeting = new Meeting();
  private meetingSubscription: Subscription;
  public attendees: Attendee[] = [];
  private attendeeSubscription: Subscription;

  constructor(
    @Inject('Window') private window: Window,
    public goldMine: GoldmineService,
    private backendService: BackendConnectionService,
    private userService: UserService,
    public attendeeService: AttendeesService,
    public dialog: MatDialog,
    public utilities: UtilitiesService,
    private router: Router,
    private ngZone: NgZone
  ) {
    super();
  }

  //HIGHEST LEVEL OF DATA SERVICE = GOLDMINE
  ngOnInit() {
    if(this.goldMine.meetingsLoaded){
      this._init();
    } else {
      this.meetingSubscription = this.goldMine.meetingsSubject.subscribe(
        () => this._init()
      );
    }
  }

  private _init(): void{
    this.meeting = this.goldMine.meeting;
    this.getAttendees();
    if(this.meeting.linkedMeeting){
        this.attendeeService.getLinkedAttendees(this.meeting.linkedMeeting.child_mid);
    }
  }

  private getAttendees(){
    this.attendeeSubscription = this.attendeeService.attendeesSubject.subscribe(
      (res: Attendee[]) => {
        this.attendees = res;
      }
    );
    this.attendeeService.getAttendees(this.meeting.mid);
  }

  private removeDistMtg(accounts: any[]){
    this.backendService.postRequest(this.userService.token, '/removeDistMtg', 
      { meetDate: this.goldMine.weekday.convertDate.toISOString(),
        accounts: accounts
      })
      .subscribe(
        ()=>{}, 
        (err) => {console.log(err);}
        );
  }

  public deleteAttendee(index: number): void{
    this.backendService.deleteRequest(this.userService.token, 
      `/deleteDb?table=attendees&id=${this.attendees[index].aid}`)
      .subscribe(
        () => {},
        (err) => console.log(err),
        () => { //ON COMPLETE
          let attendee = this.attendees[index];
          this.meeting.primaryContact.splice(
            this.meeting.primaryContact.findIndex((person)=>{
              return person.full_name == attendee.full_name;
            }), 1
          );
          if(attendee.is_confirmed){
            //this.removeDistMtg([attendee.gm_account_num]);
            this.meeting.attendeeCount--;
          }
          this.attendees.splice(index, 1);
        }
      );
  }

  public toggleConfirmed(index: number): void{
    let attendee = this.attendees[index];
    if(attendee.is_meeting_contact){ //Handle Meeting Contact
      attendee.is_meeting_contact = 0;
      this.meeting.primaryContact.splice(
        this.meeting.primaryContact.findIndex((person)=>{
          return person.full_name == attendee.full_name;
        }), 1
      );
    }
    if(attendee.is_confirmed){
      //this.removeDistMtg([attendee.gm_account_num]);
      attendee.is_confirmed = 0;
      attendee.mark_email_change = 1;
      this.meeting.attendeeCount--;
    } else {
      // this.backendService.postRequest(this.userService.token, '/addDistMtg', 
      // { meetDate: this.goldMine.weekday.convertDate.toISOString(),
      //   accounts: [ 
      //     attendee.gm_account_num
      //   ]
      // })
      // .subscribe(
      //   ()=>{}, 
      //   (err) => {console.log(err);}
      //   );
      attendee.is_confirmed = 1;
      attendee.mark_email_change = 1;
      this.meeting.attendeeCount++;
    }
    attendee.touched = true;
  }

  public toggleMeetingContact(index: number){
    let attendee = this.attendees[index];
    if(!attendee.is_confirmed){
      attendee.is_confirmed = 1;
      this.meeting.attendeeCount++;
    }
    if(attendee.is_meeting_contact == 0){
      attendee.is_meeting_contact = 1;
      this.meeting.primaryContact.push({
        full_name: attendee.full_name,
        phone1: attendee.phone1,
        phone2: attendee.phone2,
        email: attendee.email,
        title: attendee.title
      });
    } else {
      attendee.is_meeting_contact = 0;
      this.meeting.primaryContact.splice(
          this.meeting.primaryContact.findIndex((person)=>{
            return person.full_name == attendee.full_name;
          }), 1
      );
    }
    attendee.touched = true;
  }

  /**
   * OPENS THE ADD CONTACT MODAL AND PUSHES THE RETURNED CONTACT TO ATTENDEES ARRAY
   */
  public addContact(){
    let dialogRef;
    this.ngZone.run(()=>{
        let dialogData = {
            meeting: this.meeting,
          attendees: this.attendees
        }
        if(this.meeting.linkedMeeting){
            dialogData['linkedAttendees'] = this.attendeeService.linkedAttendees
        } else {
            dialogData['linkedAttendees'] = []
        }
      dialogRef = this.dialog.open(AddContactComponent, {data: dialogData});
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result && !result.aid){
          
        let insertReponseData = { 
          aid: null,
          service_team_flyer: null
        };

        //Extra data
        if(this.attendees.length < 1 && !this.meeting.isTradeShow){
            result.is_meeting_contact = 1;
            this.meeting.primaryContact.push({
                full_name: result.full_name,
                phone1: result.phone1,
                phone2: result.phone2,
                email: result.email,
                title: result.title
            });
        } else {
            result.is_meeting_contact = 0;
        }

        this.backendService.postRequest(this.userService.token, '/insertAttendee',
        {
          meetDate: this.goldMine.weekday.convertDate.toISOString(),
          contact: result
        })
        .subscribe(
          (res) => {
            insertReponseData.aid = Number(res.insertId);
            insertReponseData.service_team_flyer = Number(res.stf);
          },
          (err) => { console.log(err); },
          () => {
            if(insertReponseData.aid){
              result = {...result, ...insertReponseData};
              this.pushNewAttendee(result);
            } else {
              console.log("Insert did not return with an ID");
            }
          }
        );
      } else if(result && result.aid){
        //results coming from the raw add
        this.pushNewAttendee(result);
      }
    });
  }

  public updateContact( index: number ){
    const dialogRef = this.dialog.open(GMContactUpdateComponent, {
      data: {
        attendees: this.attendees,
        index: index
      }
    });
  }

  private pushNewAttendee(data: any){
    data.is_confirmed = true;
    data.color = this.utilities.color;
    this.attendees.push(data);
    this.meeting.attendeeCount++;
  }

  public openPhoneDialog(contact){
    const dialogRef = this.dialog.open(PhoneSelectComponent, {
      data: [contact]
    });
  }

  public printRoster(){
    var doc = new jsPDF();
    var roster = [];
    this.attendees.forEach( (person) =>{
      roster.push([
        '',
        person.full_name,
        person.title,
        person.email,
        person.phone1
      ]);
    });
    //push 3 blank lines at the end of the roster
    for(let x = 0; x < 9; x++){
      roster.push(['','','','','']);
    }
    doc.text('"' + this.meeting.company + 
      '" ' + this.utilities.dateToStringMonthDay(this.goldMine.weekday.convertDate) + ", " +
      this.utilities.formatTime(this.meeting.ontime), 14, 15);
    doc.autoTable({
      startY: 20,
      head: [
        ['Attended', 'Name', 'Title', 'Email', 'Phone #']
      ],
      body: roster,
      styles: {
        lineWidth: 0.2
      }
    });
    doc.autoPrint();
    doc.output('dataurlnewwindow');
  }

  gotoAttendeeNotes(aid: number){
      console.log(aid);
      this.router.navigate([`/splash/details/notes`, { aid: aid }]);
  }

  private _destroy(){
    if(this.meetingSubscription) this.meetingSubscription.unsubscribe()
    if(this.attendeeSubscription) this.attendeeSubscription.unsubscribe()
    this.attendeeService.updateAttendees(); //save data to database
    this.attendeeService.linkedAttendees.length = 0; //Hack, just remove attendees from this list everytime.
  }

  ngOnDestroy(){
    this._destroy();
  }

  canDeactivate(){
    return false;
  }
}
