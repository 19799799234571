import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { GoldmineService } from '@app/core/services/goldmine.service';
import { Meeting } from '@app/core/models/goldmine.model';
import { Subscription } from 'rxjs';
import { BackendConnectionService } from '@app/core/services/backend.connection.service';
import { UserService } from '@app/core/services/user.service';
import { ComponentCanDeactivate } from '@app/modules/auth/can-deactivate/component-can-deactivate';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss']
})

export class NotesComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

    private isMeeting: boolean; //What kind of note are we processing?

    private aid: number; //attendee id if applicable.
    public attName: string = ""; //Name of attendee if applicable

    private meeting: Meeting;
    private meetingSubscription: Subscription

    private notesOrig: string;
    public notesValue: string;

    public innerHeight: number;
    

    constructor(
        private goldMine: GoldmineService,
        private backendService: BackendConnectionService,
        private userService: UserService,
        private route: ActivatedRoute
    ) {
        super();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event){
        this.innerHeight = event.target.innerHeight;
    }

    //HIGHEST SERVICE LEVEL = GOLDMINE
    ngOnInit() {
        document.addEventListener('touchmove', e => e.preventDefault);
        this.route.params.subscribe( (params: Params) => {
            if(params.aid){
                this.isMeeting = false;
                this.aid = params.aid;
                this.backendService.getRequest(this.userService.token, 
                    `/fetchDb?select=notes,full_name&table=attendees&column=aid&value=${params.aid}`)
                .subscribe(
                    (res: any) => {
                        this.attName = res[0].full_name;
                        this.notesValue = this.notesOrig = res[0].notes;
                    },
                    (err) => console.log(err)
                );
            } else {
                this.isMeeting = true;
                if (this.goldMine.meetingsLoaded) {
                    this._initMeeting();
                } else {
                    this.meetingSubscription = this.goldMine.meetingsSubject.subscribe(
                        () => this._initMeeting()
                    );
                }
            }
        });

        this.innerHeight = window.innerHeight;
    }

    private _initMeeting() {
        this.meeting = this.goldMine.meeting;
        this.notesValue = this.meeting.convertedNotes;
        this.notesOrig = this.notesValue;
    }

    public saveNotes() {
        if (this.notesOrig != this.notesValue) {
            if(this.isMeeting)
                this.meeting.convertedNotes = this.notesValue;
            this.backendService.postRequest(this.userService.token, '/updateDb', {
                table: this.isMeeting? 'meetings' : 'attendees',
                data: [{
                    notes: this.notesValue,
                    id: this.isMeeting? this.meeting.mid : this.aid
                }]
            }).subscribe(
                () => { },
                (err) => {
                    console.log(err);
                    alert("Warning, changes may not have been saved!\nPlease check your connection!\n"
                        + "Error:" + err);
                },
                () => {
                    //We can safely clear local storage cause notes live in the database now.
                }
            );
        }
    }

    public saveNotesFromButton(){
        this.saveNotes();
    }

    private _destroy() {
        this.saveNotes();
        if (this.meetingSubscription) this.meetingSubscription.unsubscribe();
    }

    ngOnDestroy() {
        this._destroy();
    }

    canDeactivate() {
        return false;
    }
}

