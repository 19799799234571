import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
//MODELS
import { User } from '../models/user.model';
//COMPONENTS AND SERVICES
import { Config } from '../config';
import { UserConnection } from '@app/core/services/user.connection.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user = new User(0, '', '', '', '');
  public userServiceSubjet: Subject<any> = new Subject;
  private privateIsLoggedIn = false;
  private privateToken: string;
  public error: string;

  constructor(
    private userConService: UserConnection,
    private cookieService: CookieService,
    private config: Config,
    private router: Router
  ){
    this.privateToken = this.cookieService.get('meet-app');
    if(this.privateToken){
      //have to assume logged in if token is pressent
      this.privateIsLoggedIn = true;
    }
  }

  //called from splash compoenent and login component during onInit
  _init(): void{
    if(this.privateToken){
      //retreive the user info from the database
      this.userConService.refreshUser(this.privateToken)
        .subscribe(
          (res: User) => {
            this.user = res;
          },
          (err) => {
            //if error comes back from the backend, log the user out
            console.log(err);
            this.privateIsLoggedIn = false;
            this.error = err;
            this.router.navigate(['/login']);
          },
          () => {
            this.userServiceSubjet.next();
          }
        );
    }
  }

  login(data: NgForm) {
    this.userConService.login(data)
      .subscribe(
        (res: User) => {
          this.user = res;
          this.cookieService.set('meet-app', res['token'], 0 , '/');
          // var min = 0; 
          // var max = 200;  
          // var random =Math.floor(Math.random() * (+max - +min)) + +min; 
          // this.cookieService.set('mm', `mark-testing${random}`, 0, '/');
          this.privateToken = res['token'];
        },
        (err) => {
          console.log(err);
          this.error = err;
        },
        () =>{ //complete
          this.privateIsLoggedIn = true;
          data.reset();
          this.userServiceSubjet.next();
          this.router.navigate(['splash/planner']);
        }
      );
  }

  logout(): void {
    this.user = new User(0, '', '', '', '');
    this.privateIsLoggedIn = false;
    this.cookieService.deleteAll('/');
    this.cookieService.deleteAll('/splash'); //Don't know why I'm getting some cookies with this path.
    this.privateToken = null;
    this.router.navigate(['/login']);
  }

  public get isLoggedIn(){
    return this.privateIsLoggedIn;
  }

  public get token(): string{
    if(!this.cookieService.check('meet-app')){
      this.logout();
    }
    return this.privateToken;
  }
}
