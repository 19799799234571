import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-selector-dialog',
  templateUrl: './selector-dialog.component.html',
  styleUrls: ['./selector-dialog.component.scss']
})
export class SelectorDialogComponent {

  public message: SafeHtml;
  public selectorType: string; //yesNo || confirmOnly

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<SelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) 
  {
    this.message = this.sanitizer.bypassSecurityTrustHtml(this.dialogData.message);
    this.selectorType = this.dialogData.selectorType;
  }

  onNoClick(){
    this.dialogRef.close(false);
  }

  public closeDialog(userResponse: boolean){
    this.dialogRef.close(userResponse)
  }

}
