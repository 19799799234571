import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
//https://stackblitz.com/edit/demo-ngx-mat-datetime-picker?file=src%2Findex.html
@Component({
    selector: 'app-appointmentsignup-dialog',
    templateUrl: './appointmentsignup-dialog.component.html',
    styleUrls: ['./appointmentsignup-dialog.component.scss']
})
export class AppointmentsignupDialogComponent implements OnInit {

    private id: number; //`signup` id
    public meetingTitle: string;
    public bdRep: any;
    public showEmailField: boolean;
    /**
     * User Input items
     */
    public company: string;
    public email: string;
    public name: string;
    public count: string;
    public selectSubjects: Array<any> = [
        // { title: "The BIG IDEA - \"Today's Heroes\"", sel: false},
        { title: "What's New at Crystal D", sel: false},
        { title: 'Customization Options', sel: false},
        { title: 'Simple - Easy - Fast, Linking with Crystal D', sel: false},
        { title: 'Sales Tools to Promote Yourself', sel: false},
        { title: 'All of the Above', sel: false}
    ];
    public extraNotes: string;

    constructor(
        public dialogRef: MatDialogRef<AppointmentsignupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) {}


    ngOnInit() {
        if(localStorage.getItem('signupDialog')){
            let data = JSON.parse(localStorage.getItem('signupDialog'));
            this.company = data.company;
            this.name = data.name;
            this.count = data.count;
            this.extraNotes = data.extraNotes;
        }
        //TODO load select subjects and other relevant information from the database.
        this.bdRep = this.dialogData.bdRep;
        this.id = this.dialogData.id;
        this.meetingTitle = this.dialogData.meetingTitle;
        this.showEmailField = this.dialogData.custEmail? false : true;
        this.email = this.dialogData.custEmail;
    }

    onNoClick() {
        localStorage.setItem('signupDialog', JSON.stringify({
            company : this.company,
            name : this.name,
            count : this.count,
            extraNotes : this.extraNotes
        }));
        this.dialogRef.close();
    }

    public submit() {
        localStorage.removeItem('signupDialog');
        //Just format all the information into one big notes block.
        let info = `
Email: ${this.email}\n
Name: ${this.name}\n
Estimated Attendee Count: ${this.count}\n
Interested Subjects:\n`;  
        let all = false;
        this.selectSubjects.forEach(sub => {
            if(sub.title == 'All of the Above' && sub.sel)
                all = true;
        });
        this.selectSubjects.forEach(sub => {
            if(sub.sel || all && sub.title != 'All of the Above')
                info += '\t' + sub.title + '\n'
        });
        info += '\n\nExtra Notes: ' + this.extraNotes;

        this.dialogRef.close({
            bdRep: this.bdRep,
            email: this.email,
            id: this.id,
            info: info
        });
        //The old method of handling it.
        // this.dialogRef.close({
        //     nameList: this.nameList,
        //     company: this.company,
        //     activeSellingList: this.activeSellingList,
        //     selectSubjects: this.selectSubjects,
        //     extraNotes: this.extraNotes,
        //     id: this.id
        // });
    }
}
